<template>
  <VMenu
    open-on-hover
    offset-y
    left
  >
    <template #activator="{ on, attrs }">
      <VBtn
        v-bind="attrs"
        text
        :title="$t('interface_language.one')"
        v-on="on"
      >
        <img
          class="translate-flag elevation-1"
          :src="currentFlag"
          :alt="lang"
        >
      </VBtn>
    </template>

    <VList dense>
      <VListItemGroup
        v-model="lang"
        mandatory
        @change="onChange"
      >
        <VListItem value="en">
          <VListItemIcon>
            <img
              class="translate-flag"
              :src="flagGB"
              alt=""
            >
          </VListItemIcon>
          <VListItemContent>
            <VListItemTitle>English</VListItemTitle>
          </VListItemContent>
        </VListItem>
        <VListItem value="ru">
          <VListItemIcon>
            <img
              class="translate-flag"
              :src="flagRU"
              alt=""
            >
          </VListItemIcon>
          <VListItemContent>
            <VListItemTitle>Русский</VListItemTitle>
          </VListItemContent>
        </VListItem>
      </VListItemGroup>
    </VList>
  </VMenu>
</template>

<script>
import flagRU from '@/assets/images/ru.svg'
import flagGB from '@/assets/images/gb.svg'
import { loadLanguageAsync } from '@/i18n/i18n'
import { getCookie } from '@/utils/common'
import { request } from '@/utils/request'

export default {
  name: 'LocaleSwitch',

  data () {
    return {
      flagRU,
      flagGB,
      lang: getCookie('lang') || 'ru'
    }
  },

  computed: {
    currentFlag () {
      return this.lang === 'ru' ? this.flagRU : this.flagGB
    }
  },

  methods: {
    onChange () {
      loadLanguageAsync(this.lang)
      request(`/account?lang=${this.lang}`, {}, true)
    }
  }
}
</script>
<style lang="scss">
  .translate-flag {
    width: 1.5rem;
    height: auto;
  }
</style>
